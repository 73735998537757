
<template>
 <NavbarSection  titleClass="ml-4 " :section="$t('Contacto')" />
  <div class="container-artisan">
    <aside>
      <div class="container-creative">
        <!-- Tab Links -->
        <div class="tab">
          <button class="tabLinks age" id="defaultOpen">
            <div class="button-wrap">
              <img :src="`${$apiDevuelvoya}images/shared/svg/call.svg`" alt="" />
              <p class="titulo-creative uno">{{ $t('Contacto') }}</p>
            </div>
          </button>
          <button class="tabLinks occupation">
            <div class="button-wrap">
              <img :src="`${$apiDevuelvoya}images/shared/svg/personal.svg`" alt="" />
              <p class="titulo-creative dos">{{ $t('Empleo') }}</p>
            </div>
          </button>
          <button class="tabLinks car">
            <div class="button-wrap">
              <img :src="`${$apiDevuelvoya}images/shared/svg/flotas.svg`" alt="" />
              <p class="titulo-creative tres">{{ $t('Flotas') }}</p>
            </div>
          </button>
          <button class="tabLinks maps">
            <div class="button-wrap">
              <img :src="`${$apiDevuelvoya}images/shared/svg/map_gps.svg`" alt="" />
              <p class="titulo-creative cuatro">{{ $t('Cobertura') }}</p>
            </div>
          </button>
          <button class="tabLinks portfolio">
            <div class="button-wrap">
              <img :src="`${$apiDevuelvoya}images/shared/svg/laptop.svg`" alt="" />
              <p class="titulo-creative cinco">{{ $t('Historial') }}</p>
            </div>
          </button>
        </div>

        <!-- Tabs Content -->
        <div class="tabContent ageContent">
          <div class="tabContent-wrap">
            <div class="tabContent-des">
              <h5>{{ $t('Enviar paquetes con Express es rápido y seguro.') }}</h5>
              <p>
                {{ $t('Si necesitas información que no está disponible en nuestro sitio web, estamos aquí para ayudarte. Si deseas recibir asesoramiento general sobre el traslado y recuperación o si tienes preguntas sobre productos específicos.') }}
              </p>
              <ul class="lista-contacto">
                <li>{{ $t('Telefono: PARAGUAY +595-9839-28203') }}</li>
                <li>{{ $t('Telefono: URUGUAY +598-22051878') }}</li>
                <li>{{ $t('Email') }} : comercial@expressmetropolitana.com</li>
              </ul>
            </div>
            <div class="tabContent-img">
              <img class="image-creative" :src="`${$apiDevuelvoya}images/shared/svg/contacto.svg`" alt="" />
            </div>
          </div>
        </div>
        <div class="tabContent occupationContent">
          <div class="tabContent-wrap">
            <div class="tabContent-des">
              <h5>{{ $t('Ser parte de Express') }}</h5>
              <p>
                {{ $t('Es una gran oportunidad de crecer empresarialmente, donde podrás descubrir tu potencial en diferentes áreas.') }}
              </p>
            </div>
            <div class="tabContent-img">
              <img class="image-creative" :src="`${$apiDevuelvoya}images/shared/svg/empleo.svg`" alt="" />
            </div>
          </div>
        </div>
        <div class="tabContent carContent">
          <div class="tabContent-wrap">
            <div class="tabContent-des">
              <h5>{{ $t('A tu disposición') }}</h5>
              <p>
                {{ $t('Tenemos variedad de flotas para cumplir cada tarea específica para siempre brindarle mayor comodidad al cliente.') }}
              </p>
            </div>
            <div class="tabContent-img">
              <img class="image-creative" :src="`${$apiDevuelvoya}images/shared/svg/car.svg`" alt="" />
            </div>
          </div>
        </div>
        <div class="tabContent mapsContent">
          <div class="tabContent-wrap">
            <div class="tabContent-des">
              <h5>{{ $t('Donde estes') }}</h5>
              <p>{{ $t('Estamos nosotros, para brindarte asistencia y que realizes el mejor trabajo.') }}</p>
            </div>
            <div class="tabContent-img">
              <img class="image-creative" :src="`${$apiDevuelvoya}images/shared/svg/gps.svg`" alt="" />
            </div>
          </div>
        </div>
        <div class="tabContent portfolioContent">
          <div class="tabContent-wrap">
            <div class="tabContent-des">
              <h5>{{ $t('Seguimiento') }}</h5>
              <p>
                {{ $t('y evaluación de lo que has gestionado con nosotros, así podrás llevar registro y control de todas tus transacciones.') }}
              </p>
            </div>
            <div class="tabContent-img">
              <img class="image-creative" :src="`${$apiDevuelvoya}images/shared/svg/historial.svg`" alt="" />
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>

  <div class="container-artisan">
    <div class="accordion">
      <div class="accordion-item">
        <div class="accordion-item-header">
          {{$t('¿Qué es un comercio Express y qué ventajas tiene?')}}
        </div>
        <div class="accordion-item-body">
          <div class="accordion-item-body-content">
            {{$t('Un comercio express es una sucursal adherida a Express metropolitana, cumple la función de recepcionar equipos previamente pactados. Esta opción es la mejor para generar ingresos extras en tu comercio.')}}
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-item-header">{{$t('¿Qué es un Recolector / Repartidor?')}}</div>
        <div class="accordion-item-body">
          <div class="accordion-item-body-content">
            <ul>
              <li>
                  {{$t('Recolector: Se encarga de visitar los domicilios donde están ubicados los equipos a recuperar, para su búsqueda y rendición.')}}
              </li>
              <li>
                  {{$t('Repartidor: Se encarga de buscar equipos, repuestos, documentos y objetos que se necesiten para trasladar desde un punto A determinado a otro punto B, es un tiempo estimado.')}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-item-header">{{ $t('¿Cual es el horario de Recuperación?') }}</div>
        <div class="accordion-item-body">
          <div class="accordion-item-body-content">
            <ul style="padding-left: 1rem">
              {{ $t('La distribución es de lunes a viernes de 08 a 18 hs, Sabados y domingos.') }}
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-item-header">{{ $t('¿Cual es el horario de Repartidores?') }}</div>
        <div class="accordion-item-body">
          <div class="accordion-item-body-content">
            {{ $t('La distribución es de lunes a viernes de 08 a 18 hs, Sabados y domingos.') }}
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-item-header">{{ $t('¿Como solicito el servicio de Express?') }}</div>
        <div class="accordion-item-body">
          <div class="accordion-item-body-content">
            <ul style="padding-left: 1rem">
              {{ $t('Podés solicitar el servicio a través de Express Productos, donde eligiras el producto que mas se ajuste a tus necesidades.') }}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Footer,
  },
  mounted() {
    // ---------------TABLA INFORMATIVA DE CONTACTO---------------------------

    // Get all button element
    const tabButton = document.querySelectorAll('.tabLinks');
    const tabContent = document.querySelectorAll('.tabContent');

    window.onload = () => {
      document.getElementById('defaultOpen').click();
    };

    // Hide content function
    const hideContent = () => {
      tabContent.forEach((content) => {
        
        content.style.display = 'none';
      });
    };

    tabButton.forEach((tab) => {
      tab.addEventListener('click', () => {
        const contentClass = `${tab.classList[1]}Content`;
        hideContent();
        document.querySelector(`.${contentClass}`).style.display = 'block';
      });
    });

    // --------------------ACORDION DE INFORMACIOM-------------------------------

    const accordionItemHeaders = document.querySelectorAll('.accordion-item-header');

    accordionItemHeaders.forEach((accordionItemHeader) => {
      accordionItemHeader.addEventListener('click', () => {
        accordionItemHeader.classList.toggle('active');
        const accordionItemBody = accordionItemHeader.nextElementSibling;
        if (accordionItemHeader.classList.contains('active')) {
          accordionItemBody.style.maxHeight = `${accordionItemBody.scrollHeight}px`;
        } else {
          accordionItemBody.style.maxHeight = 0;
        }
      });
    });
  },
};
</script>

<style>
@import '../assets/css/contacto.css';
</style>
