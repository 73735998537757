<template>

    <footer class="site-footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <h6>{{$t('Nosotros')}}</h6>
                    <p class="text-justify">{{$t('Especialistas en ultima milla, logistica inversa y directa. Express traslada paqueteria en Paraguay e Uruguay.')}}
                    </p>

                </div>

                <div class="col-xs-6 col-md-3">
                    <h6>{{$t('Navega')}}</h6>
                    <ul class="footer-links">
                        <li class="cursor-pointer"><div @click="goRoute({name:'Contacto'})">{{$t('Contacto')}}</div></li>
                        <li class="cursor-pointer"><div @click="goRoute({name:'Distribucion'})">{{$t('Distribución')}}</div></li>
                        <li class="cursor-pointer"><div @click="goRoute({name:'Logistica'})">{{$t('Logística')}}</div></li>
                        <li class="cursor-pointer"><div @click="goRoute({name:'Devolver'})">{{$t('Devolver equipo')}}</div></li>
                    </ul>
                </div>

                <div class="col-xs-6 col-md-3">
                    <h6>{{$t('Socios y empleo')}}</h6>
                    <ul class="footer-links">
                        <li class="cursor-pointer"><div @click="register()" >{{$t('Recolectores')}}</div></li>
                        <li class="cursor-pointer"><div @click="register()" >{{$t('Call Center')}}</div></li>
                        <li class="cursor-pointer"><div @click="register()" >{{$t('Comercios')}}</div></li>
                    </ul>
                </div>
            </div>
            <hr>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-sm-6 col-xs-12">
                    <p class="copyright-text">
                        <a href="#">Devuelvoya</a>.
                    </p>
                </div>

                <div class="col-md-4 col-sm-6 col-xs-12">
                    <ul class="social-icons">
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  methods: {
    goRoute(path) {
      this.$router.push(path);
    },
    register() {
      document.location.href = `${process.env.VUE_APP_CLIENT}trabajar`;
    },
  },
};
</script>

<style>

</style>
