<template>
     <NavbarSection  titleClass="ml-4 " :section="$t('Distribución')" />
    <div class="top-margen">
        <div  class="ciudad">
            <main>
                <div class="way way-l">
                    <div class="cube">
                        <div class="cube__face cube__face--front">
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__door"></div>
                        </div>
                        <div class="cube__face cube__face--back">
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                        </div>
                        <div class="cube__face cube__face--right">
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                        </div>
                        <div class="cube__face cube__face--left">
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-4"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                        </div>
                        <div class="cube__face cube__face--top"></div>
                        <div class="cube__face cube__face--bottom"></div>
                    </div>
                </div>
                <div class="way way-r">
                    <div class="cube">
                        <div class="cube__face cube__face--front">
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__door"></div>
                        </div>
                        <div class="cube__face cube__face--back">
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                        </div>
                        <div class="cube__face cube__face--right">
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-4"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window "></div>
                        </div>
                        <div class="cube__face cube__face--left">
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-4"></div>
                        </div>
                        <div class="cube__face cube__face--top"></div>
                        <div class="cube__face cube__face--bottom"></div>
                    </div>
                    <div class="cube">
                        <div class="cube__face cube__face--front">
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-4"></div>
                            <div class="cube__door"></div>
                        </div>
                        <div class="cube__face cube__face--back">
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-2"></div>
                        </div>
                        <div class="cube__face cube__face--right">
                            <div class="cube__window cube__window-light-2"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-4"></div>
                            <div class="cube__window cube__window-light-1"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window "></div>
                        </div>
                        <div class="cube__face cube__face--left">
                            <div class="cube__window cube__window-light-5"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window "></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-3"></div>
                            <div class="cube__window"></div>
                            <div class="cube__window cube__window-light-4"></div>
                        </div>
                        <div class="cube__face cube__face--top"></div>
                        <div class="cube__face cube__face--bottom"></div>
                    </div>
                </div>
                <div class="bridge bridge-l">
                </div>
                <div class="bridge bridge-r"></div>
                <span class="ad">

                    <img :src="`${$apiDevuelvoya}images/shared/pos.png`" style="width: 70px; height: 40px; position: relative; left: -30%;" alt="">
                <img :src="`${$apiDevuelvoya}images/shared/man.png`" style="width: 50px; height: 30px; position: relative; left: -58%;" alt="">
                </span>
                <span class="txt" id="txt"></span>

                <div class="truck">
                    <div class="truck-f">
                        <div class="truck-f__face truck-f--front">
                            <div class="truck__handle"></div>
                        </div>
                        <div class="truck-f__face truck-f--back">
                            <div class="truck__handle"></div>
                        </div>
                        <div class="truck-f__face truck-f--left"></div>
                        <div class="truck-f__face truck-f--righ"></div>
                        <div class="truck-f__face truck-f--top"></div>
                        <div class="truck-f__face truck-f--bottom"></div>
                    </div>
                    <div class="truck-b">
                        <div class="truck-b__face truck-b--front">
                            <img src="<?=base_url?>estilos/imagenes/expresssinnombre.png" style="width: 16px; height: 15px;" alt="">
                            <span class="truck__txt"></span>
                        </div>
                        <div class="truck-b__face truck-b--back">
                            <img src="<?=base_url?>estilos/imagenes/expresssinnombre.png" style="width: 16px; height: 15px;" alt="">
                            <span class="truck__txt"></span>
                        </div>
                        <div class="truck-b__face truck-b--left"></div>
                        <div class="truck-b__face truck-b--right"></div>
                        <div class="truck-b__face truck-b--top"></div>
                        <div class="truck-b__face truck-b--bottom"></div>
                    </div>

                    <div class="truck-ls">
                        <div class="truck-l"></div>
                        <div class="truck-l"></div>
                        <div class="truck-l"></div>
                    </div>

                    <div class="truck-w truck-w--lf"></div>
                    <div class="truck-w truck-w--lb"></div>
                    <div class="truck-w truck-w--rf"></div>
                    <div class="truck-w truck-w--rb"></div>
                    <div class="truck-w truck-w--rf-2"></div>
                    <div class="truck-w truck-w--rb-2"></div>

                </div>

            </main>
        </div>
        <!-- ------------------------------------------- -->
        <div class="clearfix"></div>

        <div class="container-artisan">
            <div class="titulo-secundario-beneficios">
                <h3>{{$t('Y mas beneficios')}}</h3>

            </div>
        </div>
        <div class="container-artisan">
            <!-- beneficio 1-------- -->
            <div class="contenido-beneficio-uno">
                <div class="info-uno">
                    <div class="info-uno-contenedor-de-titulo">
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="tachometer" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Acción inmediata de equipos.')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="tachometer" />
                                <h4>{{$t('Gestionamos tus equipos al recibir base.')}}</h4>
                                <p>{{$t('Sabemos la importancia que son los equipos para tu empresa')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="clock" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Recupero en tiempo real')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="clock" />
                                <h4>{{$t('Con nuestro sistema')}}</h4>
                                <p>{{$t('El cliente sabe en tiempo real cuando tendra el contacto con el recolector o repartidor')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="store" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Amplia red de comercios adheridos')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="store" />
                                <h4>{{$t('Entrega en')}} <span style="color:#EF4848;" >{{$t('correo')}}</span> {{$t('privado y público.')}}</h4>
                                <h4>{{$t('Entrega en')}} <span style="color:#EF4848;" >{{$t('comercios')}}</span> {{$t('Red Express')}}</h4>
                                <h4>{{$t('Entrega en')}} <span style="color:#EF4848;" >{{$t('terminales de')}}</span> {{$t('omnibus')}}</h4>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="mobile" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('El cliente elige cuando y donde')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="mobile" />
                                <h4>{{$t('El cliente no tiene un domicilio fijo.')}}</h4>
                                <p>{{$t('Express brinda la posibilidad de adaptarse a los cambios repentinos que el cliente requiera. Asi como tambien la alta flexibilidad de modificación en horarios de entregas')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="truck" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Correo privados y publicos')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="truck" />
                                <h4>{{$t('Totalmente gratis.')}}</h4>
                                <p>{{$t('Para comodidad del cliente puede enviar los equipos e insumos en la sucursal de correo mas cercana a su domicilio')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="envelope" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Entrega por Terminales de Bus')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="envelope" />
                                <h4>{{$t('Totalmente gratis')}}</h4>
                                <p>{{$t('El cliente puede enviar equipos e insumos entregándolos en la sucursal de Terminales de Bus más cercana a su domicilio')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-dos">
                    <img :src="`${$apiDevuelvoya}images/shared/presente23.png`" alt="" class="imagen-beneficio-uno">
                </div>
            </div>

            <!--   beneficio 2-------- -->
            <div class="contenido-beneficio-uno">
                <div class="info-dos">
                    <img :src="`${$apiDevuelvoya}images/shared/waree.png`" alt="" class="imagen-beneficio-uno">
                </div>
                <div class="info-uno">
                    <div class="info-uno-contenedor-de-titulo">
                        <div class="caja-beneficio ">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="phone" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Contacto multinivel al cliente')}}</p>
                            </div>
                            <div class="tooltip-container-dos">
                                <font-awesome-icon style="margin:auto;" icon="phone" />
                                <h4>{{$t('Sabemos en que ambiente se mueve el cliente')}}</h4>
                                <p>{{$t('estamos en constante contacto con el. Usamos sistemas automatizados en paralelo con las herramientas mas populares del momento')}}</p>
                                <div style="display:flex;flex-direction:flex-row;">
                                    <img class="img-contacto"
                                        :src="`${$apiDevuelvoya}images/shared/sms.png`"
                                        alt="">
                                    <img class="img-contacto"
                                        :src="`${$apiDevuelvoya}images/shared/whatsapp.png`" alt="">
                                    <img class="img-contacto"
                                        :src="`${$apiDevuelvoya}images/shared/facebook.png`" alt="">
                                    <img class="img-contacto"
                                        :src="`${$apiDevuelvoya}images/shared/correo-electronico.png`" alt="">
                                    <img class="img-contacto"
                                        :src="`${$apiDevuelvoya}images/shared/telefono.png`" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="file-invoice" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Remitos eletronicos')}}<br>{{$t('personalizados')}}</p>
                            </div>
                            <div class="tooltip-container-dos">
                                <font-awesome-icon style="margin:auto;" icon="file-invoice" />
                                <h4>{{$t('Papel cero')}}</h4>
                                <p>{{$t('El cliente recibe un remito digital automaticamente al finalizar cada transacción, asi facilmente llevara un control de su gestión y respaldo de la misma')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="box" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Administración y rendicion por lote')}}</p>
                            </div>
                            <div class="tooltip-container-dos">
                                <font-awesome-icon style="margin:auto;" icon="box" />
                                <h4>{{$t('Express administra los equipos recuperados')}}</h4>
                                <p>{{$t('Organizamos y realizamos la rendicion por lote')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="clock" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Informacion de recupero y stock en tiempo real')}}</p>
                            </div>
                            <div class="tooltip-container-dos">
                                <font-awesome-icon style="margin:auto;" icon="clock" />
                                <h4>{{$t('Historial de gestión.')}}</h4>
                                <p>{{$t('Tendras a tu disposición el seguimiento total de la gestión para tus clientes en tiempo real.')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="building" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Gestión de campañas no propias')}}</p>
                            </div>
                            <div class="tooltip-container-dos">
                                <font-awesome-icon style="margin:auto;" icon="building" />
                                <h4>{{$t('Entregas en zonas criticas.')}}</h4>
                                <p>{{$t('Gestión de stocks residuales de camapañas no propias')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="intro-area transparent" id="intro">
            <div class="container-artisan">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="intro-block" data-toggle="modal" data-target="#ModalRecuperacion">
                            <span class="intro-icon"><i class="fas fa-door-open"></i></span>
                            <h3>{{$t('Recuperación y búsqueda')}}</h3>
                            <p>
                                {{$t('Sabemos la importancia que representan en activos para la empresa, teniendo diferentes mecanismos efectivos para la recuperación de equipos')}}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="intro-block" data-toggle="modal" data-target="#ModalTraslados">
                            <span class="intro-icon"><i class="fas fa-truck"></i></span>
                            <h3>{{$t('Traslado y envios')}}</h3>
                            <p>
                                {{$t('Te brindamos la oportunidad de trasladar productos, materiales. Express se encarga de la logistica inversa, directa y ultima milla')}}
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="intro-block" data-toggle="modal" data-target="#ModalComercios">
                            <span class="intro-icon"><i class="fas fa-store-alt"></i></span>
                            <h3>{{$t('Comercios Express')}}</h3>
                            <p>
                                {{$t('Express dispone de una extensa cadena de comercios adheridos a nivel nacional e internacional. Donde el cliente dado de baja, puede entregar de manera simple el equipo')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Footer/>

</template>

<script setup>
import Footer from '@/components/Footer.vue';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

onMounted(() => {
    const text = [t('SOMOS'), t('TU MEJOR'), t('ALIADO')];
    let counter = 0;
    const elem = document.getElementById('txt');

    function change() {
      elem.innerHTML = text[counter];
      counter += 1;
      if (counter >= text.length) {
        counter = 0;
      }
    }
    setInterval(change, 1500);

    window.addEventListener('DOMContentLoaded', change);
})
</script>

<style>
@import '../assets/css/corporativodistri.css';

.img-contacto {
  text-align: center;
  margin: 0.7rem 1rem 0 0.5rem;
  width: 19px;
  height: 19.5px;
}

.top-margen{
    margin-top:280px;
}
@media (min-width: 860px) {
  .top-margen{
    margin-top:60px;
  }
}

</style>
