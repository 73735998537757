 <template>
     <NavbarSection  titleClass="ml-4 " section="Logistica" />
  <div>
    <div style="margin-top:20px" class="container-artisan">
        <div class="para-quien">
            <span class="titulo-para">{{$t('¿Qué distribuimos para vos?')}}</span>
        </div>
    </div>

    <div style="margin-top:20px;margin-bottom:20px;" class="container-artisan">
        <aside>
            <div class="containermarve">
                <!-- slider -->

                <ul id="autoplay" class="ulmarvel" style="border-radius: 20px;">

                    <!-- --------- 1--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/rollobox.png`" class="model">
                        </div>
                    </li>
                    <!-- --------- 2--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/circui.png`" class="model">
                        </div>
                    </li>
                    <!-- --------- 3--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img  :src="`${$apiDevuelvoya}images/shared/printerbox.png`" class="model">

                        </div>
                    </li>
                    <!-- --------- 4--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/parts.png`" class="model">
                        </div>
                    </li>
                    <!-- --------- 5--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/lector.png`" class="model">
                        </div>
                    </li>
                    <!-- --------- 6--------->
                    <li class="item-a">
                        <!-- slider box -->
                        <div class="boxmarve">
                            <img :src="`${$apiDevuelvoya}images/shared/mercadopost.png`" class="model">
                        </div>
                    </li>
                </ul>
            </div>
        </aside>
    </div>

    <div class="container-artisan como-lo">
        <div class="titulo-principal-beneficios">
            <h2>{{$t('¿Cómo lo hacemos?')}}</h2>
        </div>
    </div>

    <div class="container-artisan">

        <div class="container-centro">
            <div class="box-centro">
                <div class="box-icon-gestion">
                 <font-awesome-icon style="margin:auto;" icon="headset" />
                </div>
            </div>
            <div class="box-texto">
                <p>
                    {{$t('Centro de control')}}
                </p>
            </div>
        </div>

        <div class="contenido-de-box">
            <div class="box-proceso-de-gestion">

                <!-- mini box 1 -->
                <div class="mini-box">
                    <div class="circulo-mini-box">
                        <div class="encierro-icono">
                            <font-awesome-icon style="margin:auto;" icon="mobile" />
                        </div>
                    </div>
                </div>

                <!-- mini box 2 -->
                <div class="mini-box-sin">
                    <img class="imagen-del-box" :src="`${$apiDevuelvoya}images/shared/rayas1.png`" alt="">
                    <img class="imagen-del-box-2" :src="`${$apiDevuelvoya}images/shared/rayas2.png`" alt="">
                </div>

                <!-- mini box 3 -->
                <div class="mini-box">
                    <div class="circulo-mini-box">
                        <div class="encierro-icono">
                            <font-awesome-icon style="margin:auto;" icon="box" />
                        </div>
                    </div>
                </div>

                <!-- mini box 4 -->
                <div class="mini-box-sin">
                    <img class="imagen-del-box" :src="`${$apiDevuelvoya}images/shared/rayas1.png`" alt="">
                    <img class="imagen-del-box-2" :src="`${$apiDevuelvoya}images/shared/rayas2.png`" alt="">
                </div>

                <!-- minoi box 5 -->
                <div class="mini-box">
                    <div class="circulo-mini-box">
                        <div class="encierro-icono">
                            <font-awesome-icon style="margin:auto;" icon="truck" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="parrafo-abajo-de-circulos">
            <div class="contenedor-parrafos">
                <div class="parrafo uno">
                    <p>{{$t('Recibimos tu petición.')}}</p>
                </div>

                <div class="parrafo dos">
                    <p>{{$t('Buscamos lo que necesitas.')}}</p>
                </div>
                <div class="parrafo tres">
                    <p>{{$t('Te lo entregamos')}} <br> {{$t('en tu ubicación.')}}<br></p>
                </div>
            </div>
        </div>

        <div class="container-union">
            <div class="box-union">
                <i class="fas fa-headset"></i>
            </div>
            <p>{{$t('Seguimiento en vivo')}}</p>
        </div>

        <div class="container-imagen-control">
            <div class="box-imagen-control">
                <div class="minibox-imagen">
                    <img :src="`${$apiDevuelvoya}images/shared/routt.png`" alt="">
                </div>
                <div class="minibox-imagen-contacto">
                    <div class="metodos">
                        <img :src="`${$apiDevuelvoya}images/shared/whatsapp.png`" alt="">
                    </div>
                    <div class="metodos">
                        <img :src="`${$apiDevuelvoya}images/shared/facebook.png`" alt="">
                    </div>
                    <div class="metodos">
                        <img :src="`${$apiDevuelvoya}images/shared/correo-electronico.png`" class="imagen-adentro"  alt="">
                    </div>
                    <div class="metodos">
                        <img :src="`${$apiDevuelvoya}images/shared/telefono.png`" class="imagen-adentro" alt="">
                    </div>
                    <div class="metodos">
                        <img :src="`${$apiDevuelvoya}images/shared/sms.png`" class="imagen-adentro" alt="">
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="container-artisan">
        <div class="contenedor-transparente">
            <div class="box-transparente">
                <i class="icono-transparente fas fa-diagnoses"></i>
                <h4>{{$t('Gestión de stocks')}}</h4>
                <p>{{$t('Productos consumibles, insumos en general. Administración de stocks. Sin límites de SKUS.')}}</p>
                <hr>
            </div>
            <div class="box-transparente">
                <i class="icono-transparente fas fa-user-tie"></i>
                <h4>{{$t('Abastecimiento a servicios técnicos. Grandes clientes')}}</h4>
                <p>{{$t('Abastezca el servicio técnico en tiempo real. Controle eficientemente la gestión de abastecimiento de técnicos y clientes.')}}</p>
                <hr>
            </div>
            <div class="box-transparente">
                <i class="icono-transparente fas fa-shipping-fast"></i>
                <h4>{{$t('Entregas especiales')}}</h4>
                <span style="font-weight: bold;">{{$t('Servicio ultra rápido')}}</span> {{$t('Puerta a puerta en el menor tiempo posible.')}} <br>
                <span style="font-weight: bold;">{{$t('Contenedores a medida')}}</span> {{$t('Sabemos que no todos son paquetes.')}} 
                <hr>
            </div>
            <div class="box-transparente">
                <i class="icono-transparente fas fa-history"></i>
                <h4>{{$t('Logística integral')}}</h4>
                <p>{{$t('Administración de proveedores, de stocks unitizados, embalado y fulfillment en general. Disponga de CDs o microCDs de acuerdo a sus necesidades.')}}</p>
                <hr>
            </div>
        </div>
    </div>

    <section class="cuerpo-tabs">
        <div class="contenedor-tabs">
            <div class="icon-tabs">
                <div class="icon-box-tabs active" data-id="content1">
                    <img class="imagen-tabs" :src="`${$apiDevuelvoya}images/shared/roll.png`">
                </div>
                <div class="icon-box-tabs" data-id="content2">
                    <img class="imagen-tabs" :src="`${$apiDevuelvoya}images/shared/atma.png`">
                </div>
                <div class="icon-box-tabs" data-id="content3">
                    <img class="imagen-tabs" :src="`${$apiDevuelvoya}images/shared/stopwatch.png`">
                </div>
                <div class="icon-box-tabs" data-id="content4">
                    <img class="imagen-tabs" :src="`${$apiDevuelvoya}images/shared/factory.png`">
                </div>
            </div>
            <div class="content-tabs">
                <div class="content-box-tabs active" id="content1">
                    <div>
                        <h2>{{$t('Gestión de stocks')}}</h2>
                        <p>
                            {{$t('Productos consumibles , insumos en general. Administración de stocks. Sin limites de SKUS')}}
                        </p>
                        <div class="text-tabs"></div>
                    </div>
                </div>

                <div class="content-box-tabs" id="content2">
                    <div>
                        <div class="text-tabs">
                            <h2>{{$t('Abastecimiento a servicios técnicos. Grandes clientes')}}</h2>
                            <p>
                                {{$t('Abastezca el servicio tecnico en tiempo real. Controle eficientemente la gestión de abastecimiento de técnicos y clientes.')}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="content-box-tabs" id="content3">
                    <div>
                        <div class="text-tabs">
                            <h2>{{$t('Entregas especiales')}}</h2>
                            <p>
                                {{$t('Sabemos que hay temas urgentes')}}
                                <ul>
                                    <li>
                                        <span style="font-weight: bold;">{{$t('Servicio ultra rapido')}}</span> {{$t('Puerta a puerta en el menor tiempo posible.')}}
                                    </li>
                                    <li>
                                        <span style="font-weight: bold;">{{$t('Contenedores a medida')}}</span> {{$t('sabemos que no todos son paquetes.')}}
                                    </li>
                                    <li>
                                        <span style="font-weight: bold;">{{$t('Despacho internacionales')}}</span> {{$t('Despachamos y recibimos recibimos a cuenta y nombre.')}}
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="content-box-tabs" id="content4">
                    <div>
                        <div class="text-tabs">
                            <h2>{{$t('Logística integral')}}</h2>
                            <p>
                                {{$t('Administración de proveedores, de stocks unitizados, embalado y fulfillment en general. Disponga de CDs o microCDs de acuerdo a sus necesidades.')}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="container-artisan">
        <aside>
            <div class="contenido-beneficio-uno">
                <div class="info-dos">
                    <img :src="`${$apiDevuelvoya}images/shared/mapsgp.png`" alt="" class="imagen-beneficio-uno">
                    <div class="rim center">
                        <div class="rim2 center">
                            <div class="face center">
                                <div class="light"></div>
                                <div class="speaker"></div>
                                <div class="screen">
                                    <div class="content">
                                        <div class="paragraph">
                                            <div class="textoadentro">
                                                {{$t("¡Stock!")}}
                                            </div>
                                            <!-- <div class="line1"></div>
                                <div class="line2"></div>
                                <div class="line3"></div> -->
                                        </div>
                                        <div class="picture1">
                                            <img :src="`${$apiDevuelvoya}images/shared/rollo.png`" class="imagen-uno-adentro" alt="">
                                        </div>
                                        <div class="picture_row">

                                            <div class="picture2">
                                                <img :src="`${$apiDevuelvoya}images/shared/wifi.png`" class="imagen-uno-adentro-mini" alt="">
                                            </div>
                                            <div class="picture2">
                                                <img :src="`${$apiDevuelvoya}images/shared/mercadopost.png`" class="imagen-uno-adentro-mini" alt="">
                                            </div>
                                        </div>
                                        <div class="paragraph">
                                            <div class="line1"></div>
                                            <div class="line2"></div>
                                            <div class="line3"></div>
                                            <div class="textoadentro">
                                                {{$t("Equipos")}}
                                                
                                            </div>

                                            <!-- <div class="line2"></div> -->
                                            <!-- <div class="line1"></div>
                                <div class="line3"></div> -->
                                        </div>
                                        <div class="picture_row">
                                            <div class="picture2">
                                                <img :src="`${$apiDevuelvoya}images/shared/circui.png`" class="imagen-uno-adentro-mini" alt="">
                                                <!-- izquierda -->
                                            </div>
                                            <div class="picture2">
                                                <img :src="`${$apiDevuelvoya}images/shared/printer.png`" class="imagen-uno-adentro-mini" alt="">
                                                <!--derecha  -->
                                            </div>
                                        </div>
                                        <div class="picture_row">
                                            <div class="picture2">
                                                <img :src="`${$apiDevuelvoya}images/shared/mother.png`" class="imagen-uno-adentro-mini" alt="">
                                                <!-- izquierda -->
                                            </div>
                                            <div class="picture2">
                                                <img :src="`${$apiDevuelvoya}images/shared/codificador.png`" class="imagen-uno-adentro-mini" alt="">
                                                <!--derecha  -->
                                            </div>
                                        </div>
                                        <div class="paragraph">
                                            <div class="textoadentro">
                                                
                                                {{$t("¡Necesito esto!")}}
                                            </div>
                                            <br>
                                            <div class="line1"></div>
                                            <div class="line2"></div>
                                            <div class="line3"></div>
                                        </div>
                                        <div class="picture1">
                                            <img :src="`${$apiDevuelvoya}images/shared/discobox.png`" class="imagen-uno-adentro" alt="">
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="info-uno">
                    <div class="info-uno-contenedor-de-titulo">
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="people-arrows" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Seguimiento y')}} <br>
                                    {{$t('asistencia en vivo')}}
                                </p>
                                <div class="tooltip-container">
                                    <font-awesome-icon style="margin:auto;" icon="people-arrows" />
                                    <h4>{{$t('Seguis tu gestion en tiempo real')}}</h4>
                                    <p>{{$t('Te brindamos un panorama del estado de tu gestion')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="file-invoice" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Remitos eletronicos')}}<br>
                                    {{$t('personalizados')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="file-invoice" />
                                <h4>{{$t('Mas facil para el cliente')}}</h4>
                                <p>{{$t('El cliente recibe un remito digital con todos los datos de la transacción')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="money-bill" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Mejor costo + beneficio.')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="money-bill" />
                                <h4>{{$t('Mejor precio del mercado')}}</h4>
                                <p>{{$t('Con express tienes el mejor COSTO-BENFICIO. Nuestra capacidad de logistica con al mejor costo del mercado.')}}</p>
                            </div>
                        </div>
                        <div class="caja-beneficio">
                            <div class="caja-de-icono">
                                <font-awesome-icon style="margin:auto;" icon="truck" />
                            </div>
                            <div class="posicion-parrafo">
                                <p class="parraforeporte">{{$t('Cobertura nacional sin restricciones.')}}</p>
                            </div>
                            <div class="tooltip-container">
                                <font-awesome-icon style="margin:auto;" icon="truck" />
                                <p>{{$t('Estamos en toda al extension del territorio nacional, sin restricción.')}}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </aside>
    </div>

    <section class="intro-area transparent" id="intro">
        <div class="container-artisan">
            <div class="row">
                <div class="col-sm-4">
                    <div class="intro-block" data-toggle="modal" data-target="#ModalRecuperacion">
                        <span class="intro-icon"><i class="fas fa-door-open"></i></span>
                        <h3>{{$t('Traslados y busquedas')}}</h3>
                        <p>
                            {{$t('Sabemos que las empresas que prestan servicios al consumidor, siempre necesitan trasladar materiales.')}}
                        </p>
                        <a class="ver-mas">
                            <i class="icono-ver-mas fas fa-share" data-toggle="modal" data-target="#ModalRecuperacion"></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="intro-block" data-toggle="modal" data-target="#ModalTraslados">
                        <span class="intro-icon"><i class="fas fa-truck"></i></span>
                        <h3>{{$t('Reposición de stock')}}</h3>
                        <p>
                            {{$t('Con Express puedes reponer tu stock de productos y satisfacer a tus clientes. Nosotros realizamos toda la logística de última milla.')}}
                        </p>
                        <a class="ver-mas">
                            <i class="icono-ver-mas fas fa-share" data-toggle="modal" data-target="#ModalRecuperacion"></i>
                        </a>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="intro-block" data-toggle="modal" data-target="#ModalComercios">
                        <span class="intro-icon"><i class="fas fa-store-alt"></i></span>
                        <h3>{{$t('Red de comercios Express')}}</h3>
                        <p>
                            {{$t('Express dispone de una extensa cadena de comercios adheridos a nivel nacional e internacional.')}}
                        </p>
                        <a class="ver-mas">
                            <i class="icono-ver-mas fas fa-share" data-toggle="modal" data-target="#ModalComercios"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
  <Footer/>
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Footer,
  },
  mounted() {
    const iconBx = document.querySelectorAll('.icon-box-tabs');
    const contentBx = document.querySelectorAll('.content-box-tabs');

    for (let i = 0; i < iconBx.length; i += 1) {
      iconBx[i].addEventListener('mouseover', () => {
        for (let ii = 0; ii < contentBx.length; ii += 1) {
          contentBx[ii].className = 'content-box-tabs';
        }
        document.getElementById(this.dataset.id).className = 'content-box-tabs active';
        for (let iii = 0; iii < iconBx.length; iii += 1) {
          iconBx[iii].className = 'icon-box-tabs';
        }
        this.className = 'icon-box-tabs active';
      });
    }
  },

};
</script>

<style lang="css">
@import '../assets/css/home.css';
@import '../assets/css/corporativo.css';

.como-lo{
    margin-top:50px;
}
@media (min-width: 900px){
    .como-lo{
    margin-top:80px;
    }
}
@media (min-width: 1200px){
    .como-lo{
    margin-top:100px;
    }
}

</style>
